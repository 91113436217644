<template>
  <div class="ⱦheader">
    <div class="logo">
      <h1>
        <img src="https://q1.qlogo.cn/g?b=qq&nk=79598879&s=640" />
        <a href="javascript:;">今日热榜</a>
      </h1>
    </div>
    <div class="link">
      <h2>提供各站热榜热搜聚合</h2>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>