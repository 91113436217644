<template>
  <footer>
    <p>
      接口来源：<strong><a href="https://api.vvhan.com/hotlist.html" target="_blank" style="color: #5b3ff7">https://api.vvhan.com/hotlist.html</a></strong>
    </p>
    <p>声明:本站部分资源来自互联网收集,仅供用于学习和交流,请遵循相关法律法规,本站一切资源不代表本站立场,如有侵权、不妥请联系本站站长删除</p>
    <p>
      &copy; <a href="https://www.inpx.win/" target="_blank"><strong>UP IN PX</strong></a
      >ㅤMail:<strong style="color: red">admin@inpx.win</strong> | 瓶幾¬_¬ All right reserved 版权所有
    </p>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>